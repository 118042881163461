var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "white--text py-0 my-0 top-bar",
      attrs: { height: "56px", app: "" }
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "align-self-center" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/UtiliSyncDocsWhite.svg"),
              id: "logo"
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }