var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { "background-color": "#fafafa" } },
    [
      _c(
        "v-main",
        { staticClass: "pt-0", attrs: { fluid: "", tag: "section" } },
        [
          _c("LogoTopBar", { ref: "topBar" }),
          _c("section", { staticClass: "pt-8" }, [
            _c("div", { staticClass: "d-flex justify-center align-center" }, [
              _vm.isVideo
                ? _c(
                    "div",
                    { staticClass: "mt-16" },
                    [
                      _c("div", { staticClass: "text-subtitle-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.capturedDate) +
                            " at " +
                            _vm._s(_vm.capturedTime) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "text-subtitle-1 pb-2 font-weight-medium"
                        },
                        [
                          _vm._v(
                            " Captured from the " +
                              _vm._s(_vm.fromAppName) +
                              " app. "
                          )
                        ]
                      ),
                      _c(
                        "v-card",
                        [
                          _c("v-card-text", [
                            !_vm.$vuetify.breakpoint.mobile
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-center" },
                                  [
                                    _c("video", {
                                      ref: "videoElement",
                                      attrs: {
                                        controls: "",
                                        height: "450",
                                        width: "800"
                                      }
                                    })
                                  ]
                                )
                              : _c("div", [
                                  _c("video", {
                                    ref: "videoElement",
                                    attrs: {
                                      controls: "",
                                      height: "170",
                                      width: "300"
                                    }
                                  })
                                ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex pt-2" },
                        [
                          _c("v-spacer"),
                          _c("div", { staticClass: "text-subtitle-2" }, [
                            _vm._v("Captured by " + _vm._s(_vm.capturedBy))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.isImage
                ? _c(
                    "div",
                    { staticClass: "mt-16" },
                    [
                      _c("div", { staticClass: "text-subtitle-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.capturedDate) +
                            " at " +
                            _vm._s(_vm.capturedTime) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "text-subtitle-1 pb-2 font-weight-medium"
                        },
                        [
                          _vm._v(
                            " Captured from the " +
                              _vm._s(_vm.fromAppName) +
                              " app. "
                          )
                        ]
                      ),
                      _c(
                        "v-card",
                        [
                          _c("v-card-text", [
                            !_vm.$vuetify.breakpoint.mobile
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-center" },
                                  [
                                    _c("img", {
                                      ref: "imageElement",
                                      style: {
                                        "max-height": "450px",
                                        "max-width": "800px"
                                      },
                                      attrs: { src: _vm.captureURL }
                                    })
                                  ]
                                )
                              : _c("div", [
                                  _c("img", {
                                    ref: "imageElement",
                                    style: {
                                      "max-height": "170px",
                                      "max-width": "300px"
                                    },
                                    attrs: { src: _vm.captureURL }
                                  })
                                ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex pt-2" },
                        [
                          _c("v-spacer"),
                          _c("div", { staticClass: "text-subtitle-2" }, [
                            _vm._v("Captured by " + _vm._s(_vm.capturedBy))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }